import { MuBinder } from "mu-widget/lib/MuBinder";
import { MuWidget } from "mu-widget/lib/MuWidget";
import {MuLabelFor} from "mu-widget/lib/MuLabelFor";

MuWidget.registerAll(
	require("./Widgets"),
	require("./UiDirectpaidCloseForm"),
);

MuBinder.register(MuWidget);
MuWidget.startup();
MuLabelFor.register(MuWidget);

window.devClassNames = function (el: HTMLElement|string) {
	if (typeof el === "string") el = document.querySelector(el);
	console.log(Array.from(new Set(Array.from(el.querySelectorAll("[class]")).map(e => "." + e.className + " { }"))).join("\n"))
}