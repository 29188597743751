import {MuWidget} from "mu-widget/lib/MuWidget";

export class UiDirectpaidCloseForm extends MuWidget
{
	// @ts-ignore
	public priceFull: number;

	priceDirectpaid_change() {
		this.correctPrices("priceDirectpaid");
	}

	priceCachless_change() {
		this.correctPrices("priceCachless");
	}

	public bAllDirectpaid_click() {
		this.allAs("priceDirectpaid");
	}

	public bAllCachless_click() {
		this.allAs("priceCachless");
	}

	private allAs(cf: string) {
		const sf = cf == "priceDirectpaid" ? "priceCachless" : "priceDirectpaid";
		this.ui[sf].value = '0';
		this.ui[cf].value = this.priceFull.toString();
		this.ui.btDoDirectpaidClose.click();
	}

	private correctPrices(cf: string) {
		this.ui[cf].value = (Math.max(0, Math.min(this.priceFull, parseFloat(this.ui[cf].value)))).toString();
		const sf = cf == "priceDirectpaid" ? "priceCachless" : "priceDirectpaid";
		this.ui[sf].value = (this.priceFull - parseFloat(this.ui[cf].value)).toString();
	}
}