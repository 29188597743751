import { MuWidget } from 'mu-widget/lib/MuWidget';

export class Solarko extends MuWidget {

	// public urlSet: string;
	// public urlGet: string;
	public url: string = '';
	private networkInvalidLast: Date | null = null;
	public networkInvalidDelay: number = 10000;
	public statusInterval: number = 1000;
	private static firstStation: SolarkoStation;

	static getAviailableStation() : SolarkoStation
	{
		return Solarko.firstStation;
	}

	beforeIndex(): void {
		this.container.innerHTML += 
			`<span mu-id="loader">Solárko+</span> <span mu-id="stations">
				<span class="solarko__station" mu-template="station" mu-widget="SolarkoStation">
					<span mu-id="info" mu-bind='valid|tern("solarko__info","solarko__info solarko__info--warning"):className'>
						<span mu-id="status" mu-bind='requiredOutStatus|prepend("solarko__info__status solarko__info__status--"):className'>
							<span mu-bind="requiredOutStatus|translateStatus"></span>
							<span mu-bind="left|secToMinSec"></span> /
							<span mu-bind="duration|secToMinSec" mu-id="duration"></span>
							<span mu-id="info" mu-bind='valid|tern("solarko__info__status--valid","solarko__info__status--invalid"):className'>!</span>
						</span>
						<progress value="50" max="100" mu-bind="duration:max;left:value;duration:@visible"></progress>
					</span>
					<span mu-id="detail" class="solarko__detail">
						<span class="solarko__detail__timer" mu-id="timer">
							<label><input type="checkbox" mu-id="useTimer" /> Nastavit čas (minuty)</label>
							<input type="number" mu-id="cTimer" />
						</span>
						<button class="button" mu-id="bOn">Zapnout</button>
						<button class="button" mu-id="bOff">Vypnout</button>
						<button class="button" mu-id="bWait">Čekat na tlačítko</button>
					</span>
				</span>
			</span>`;
	}

	public async callController(method: ApiMethods, outNum: number|null = null, status: Status|null = null, duration: number|null = null): Promise<any>
	{
		// Omez počet dotazů pokud je chyba
		if (method === "status" && this.networkInvalidLast) {
			const diff = new Date().getTime() - this.networkInvalidLast.getTime();
			if (diff < this.networkInvalidDelay) return Promise.reject();
		}
		const loader = (state: boolean, invalid: boolean|null = null) => {
			this.ui.loader.classList.toggle('loader', state);
			if (invalid !== null) {
				this.ui.loader.classList.toggle('solarko__network_error', invalid);
				if (invalid) this.networkInvalidLast = new Date();
			}
		};
		loader(true);
		const pResponse = fetch(
			this.url + '/' + method
			+ (outNum !== null ? '/' + outNum : '')
			+ (status !== null ? '/' + status : '')
			+ (duration !== null ? '/' + duration : '')
		);
		pResponse.then(() => loader(false, false));
		pResponse.catch(() => loader(false, true));
		const response = await pResponse;

		return await response.json();
	}

	public async getStatuses(): Promise<SolarStationStatus[]>
	{
		const statuses: SolarStationStatus[] = (await this.callController('status')).map((sss: SolarStationStatus) => {
			if (sss.stopDateTime) sss.stopDateTime = new Date(sss.stopDateTime);
			return sss;
		});
		if (statuses.some(ss => !ss.valid)) this.networkInvalidLast = new Date();
		return statuses;
	}

	protected solarStations: Record<number, SolarkoStation> = {};

	afterIndex() {
		this.getStatuses().then(data => {
			for(const station of data) {
				const ss = this.solarStations[station.outNum] = this.muWidgetFromTemplate('station', 'stations') as SolarkoStation;
				ss.muBindData(station);
				Solarko.firstStation = ss;
			}
		});
		setInterval(() => this.updateStatuses(), this.statusInterval);
	}

	protected visibleDetail: boolean = false;

	/* info_click() {
		this.visibleDetail = !this.visibleDetail;
		this.updateWidget();
	}

	updateWidget() {
		this.ui.status.className = "solarko__info__status solarko__info__status--" + this.status;
		// this.ui.status.innerText = this.statusLabel[this.status];
		this.muVisible(this.visibleDetail, "detail");
		// this.muVisible(this.visibleTimer, "timer");
	} */

	updateStatuses() {
		this.getStatuses().then(data => {
			for(const station of data) {
				this.solarStations[station.outNum].muBindData(station);
			}
		});
	}
}

export class SolarkoStation extends MuWidget
{
	muParent: Solarko = null;

	private outNum: number;
	public requiredOutStatus: Status;

	protected async callController(method: ApiMethods, status: Status|null = null, duration: number|null = null): Promise<any> {
		return this.muParent.callController(method, this.outNum, status, duration);
	}

	translateStatus(status: Status): string
	{
		return StatusLabel[status];
	}

	secToMinSec(sec: number|null): string
	{
		return sec ? Math.floor(sec / 60).toString().padStart(2, '0') + ':' + (sec % 60).toString().padStart(2, '0') : '';
	}

	bOn_click() {
		this.callController(
			'setStatus',
			this.ui.useTimer.checked ? 'OnTimer' : 'On',
			(this.ui.useTimer.checked && this.ui.cTimer.value) ? parseInt(this.ui.cTimer.value) * 60 : null
		);
	}

	bOff_click() {
		this.callController(
			'setStatus',
			'Off'
		);
	}

	bWait_click() {
		const duration = (this.ui.useTimer.checked && this.ui.cTimer.value) ? parseInt(this.ui.cTimer.value) : null;
		if (duration)
			this.doWaitForStart(duration * 60);
		else
			alert('Není zadán počet minut.');
	}

	public doWaitForStart(duration: number) {
		this.callController(
			'setStatus',
			'WaitForStart',
			duration
		);
	}

	muBindData(srcData: any) {
		this.outNum = srcData.outNum;
		this.requiredOutStatus = srcData.requiredOutStatus;
		super.muBindData(srcData);
	}

	info_click() {
	   this.muVisible('toggle', 'detail')
	}

	useTimer_input() {
		this.muVisible(this.ui.useTimer.checked, 'cTimer');
		if (this.ui.useTimer.checked) this.ui.cTimer.focus();
	}

	afterIndex() {
		this.info_click();
		this.useTimer_input();
	}
}

export const StatusLabel: Record<Status, string> = {
	Off: 'Vypnuto',
	On: 'Zapnuto trvale',
	OnTimer: 'Zapnuto odpočet',
	WaitForStart: 'Čeká na spuštění'
}

export type Status ="On"|"Off"|"WaitForStart"|"OnTimer";

export type ApiMethods = "start"|"status"|"setStatus";

export type SolarStationStatus = {
	outNum: number,
	outStatus: false,
	requiredOutStatus: Status,
	stopDateTime: Date|null,
	duration: number|null,
	left: number|null,
	valid: boolean,
}

export class SolarkoSell extends MuWidget {
	beforeIndex() {
		const bSubmit = this.container.querySelector('[name="btnSubmit"]');
		bSubmit?.setAttribute('mu-id', 'bSubmit');

		const bStartAndSubmit = document.createElement('span');
		bStartAndSubmit.setAttribute('mu-id', 'bStartAndSubmit');
		bStartAndSubmit.classList.add('button');
		bStartAndSubmit.innerText = 'Zapnout a prodat';

		bSubmit?.parentElement?.insertBefore(bStartAndSubmit, bSubmit);

		const cPrice = this.container.querySelector('[name="price"]') as HTMLInputElement;
		cPrice?.setAttribute('mu-id', 'price');
		cPrice.readOnly = true;

		const form = this.container.querySelector('[class="form"]');
		for(let i = 0; i < 4; i++) form.children[i].style.display = "none";
		form.innerHTML = `<div class="solarko_sell">
			<label mu-for="duration">Čas (minuty)</label>
			<input type="number" mu-id="duration" />
			<label mu-for="pricePerMinute">Cena za minutu</label>
			<input type="number" mu-id="pricePerMinute" value="20" />
		</div>` + form.innerHTML;
	}

	afterIndex() {
		this.ui.duration.focus();
		this.ui.pricePerMinute.value = this.ui.price.value;
	}

	duration_input() {
		this.computePrice();
	}
	pricePerMinute_input() {
		this.computePrice();
	}

	private computePrice() {
		this.ui.price.value = parseInt(this.ui.duration.value) * parseInt(this.ui.pricePerMinute.value);
	}

	bStartAndSubmit_click() {
		const ss = Solarko.getAviailableStation();
		if (ss?.requiredOutStatus !== "Off") {
			alert("Solárko se právně používá, nebo není dostupné.")
		} else {
			ss.doWaitForStart(parseInt(this.ui.duration.value) * 60);
			this.ui.bSubmit.click();
		}
	}
}

// type SolarkoStatus = "on"|"off"|"wait";

export class ProductReorder extends MuWidget {
public saveUrl: string;
afterIndex()
{
	$(this.container).find('ul').sortable();
}
saveOrder()
{
	var ids = [];
	$(this.container).find('li').each(function() {
		ids.push(this.attributes['data-id'].value);
	});
	var url = this.saveUrl.replace('__ids__', ids.join(','));
	$.ajax({
		url: url,
		success: function(result) {
			alert('Uloženo');
		}
	});
	// console.log(this);
}
}